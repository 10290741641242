import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useInspectionsVehicleDamages } from '@/api/Inspections';

import { DamageDataGrid } from './components/DamageDataGrid';
import { InspectionPageTab } from '../types';

export const Damage: FC<InspectionPageTab> = ({ inspection }) => {
  const { id } = inspection;

  const { isLoading: isDamagesLoading, data: damagesData } =
    useInspectionsVehicleDamages(id);

  if (isDamagesLoading || !damagesData?.entities) return <Loader />;

  return (
    <PageContainer fluid>
      <DamageDataGrid
        inspection={inspection}
        dataSource={damagesData.entities}
      />
    </PageContainer>
  );
};
