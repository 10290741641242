import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import { Column, DataGrid, LoadPanel, Paging, Pager, FilterRow } from '@/ui';
import { faDownload, Icon } from '@/ui/components/Icon';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { isPagerVisible } from '@/utils/helpers';

import { Link, useParams } from 'react-router-dom';
import { Document } from '@/types/Documents/Documents';
import { DocumentsDataGridProps } from './types';

const PAGE_SIZE = 16;

const linkWrapper = (to: string, value: string | JSX.Element) => (
  <StyledLinkContainer>
    <StyledLink>
      <Link to={to}>{value}</Link>
    </StyledLink>
  </StyledLinkContainer>
);

export const DocumentsDataGrid: FC<DocumentsDataGridProps> = ({
  dataSource,
  vin,
}) => {
  const { t } = useTranslate();
  const { id } = useParams();

  const onRenderNumbersCell = useCallback(
    ({ rowIndex }) => `${rowIndex + 1}.`,
    [],
  );

  const onRenderActionsCell = useCallback(
    ({ data: { show, fileUrl } }) => {
      const value = <Icon icon={faDownload} />;

      if (show) return linkWrapper(fileUrl(id), value);

      return value;
    },
    [id],
  );

  const onRenderDescriptionLink = ({
    data: { show, description, link },
  }: {
    data: Document;
  }) => {
    if (!show) return description;

    return (
      <a
        href={link.getHref({ id: id!, vin })}
        target={link.options.target}
        rel={link.options.rel}
      >
        {description}
      </a>
    );
  };

  return (
    <DataGrid
      id="gridContainer"
      dataSource={dataSource}
      keyExpr="id"
      columnHidingEnabled
      showBorders
    >
      <LoadPanel enabled />
      <FilterRow visible />
      <Paging pageSize={PAGE_SIZE} />
      <Pager
        showInfo
        showNavigationButtons
        visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
      />
      <Column dataField="id" visible={false} />
      <Column
        caption=""
        allowFiltering={false}
        width={50}
        alignment="center"
        cellRender={onRenderNumbersCell}
      />
      <Column
        dataField="description"
        caption={t('description')}
        minWidth={100}
        cellRender={onRenderDescriptionLink}
      />
      <Column
        caption=""
        allowFiltering={false}
        width={50}
        alignment="center"
        cellRender={onRenderActionsCell}
      />
    </DataGrid>
  );
};
