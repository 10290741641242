import React from 'react';
import { useTheme } from 'styled-components';

import { useTranslate } from '@/translations';
import { PageContainer } from '@/ui/components/Page';
import { Title } from '@/ui/components/Title';
import { faCarBurst } from '@/ui/components/Icon';

import { InspectionsDataGrid } from './components';

export const Inspections = () => {
  const { t } = useTranslate();
  const { accent } = useTheme();

  return (
    <PageContainer fluid>
      <Title title={t('inspectionSearch')} icon={faCarBurst} color={accent} />
      <InspectionsDataGrid />
    </PageContainer>
  );
};
