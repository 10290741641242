import type { CustomStoreOptions } from 'devextreme/data/custom_store';

import { GetInspectionsData } from '@/types';
import { Filtering } from '@/types/General/Filtering';
import {
  FilterModelTypeMap,
  InspectionsPageFields,
  InspectionStatusMap,
  SortModelTypeMap,
} from '@/utils/constants';
import { inspections } from '@/api/Inspections';

import type {
  Filter,
  FilterItem,
  Sort,
  AddSorting,
  AddFiltering,
} from './types';

const addSorting: AddSorting = ({ sort, body }) => {
  const [{ selector: fieldName, desc }] = sort;

  return {
    ...body,
    [fieldName]: {
      ...(body[fieldName] || {}),
      isSorted: true,
      sortType: desc ? SortModelTypeMap.desc : SortModelTypeMap.asc,
    } as Filtering,
  };
};

const addFiltering: AddFiltering = ({ isMember, filter, body }) => {
  const res: GetInspectionsData = {
    ...body,
  };

  const addBodyFilters = (
    item:
      | keyof typeof InspectionsPageFields
      | FilterItem
      | 'and'
      | 'or'
      | Array<FilterItem | 'and' | 'or'>,
    idx: number,
    filterArray: Filter,
  ) => {
    // SingleFilter
    // filter: [
    //   "vin", <= item
    //   "contains",
    //   "9999",
    //   columnIndex: 4,
    //   filterValue: '9999',
    // ]
    if (!Array.isArray(item)) {
      const [fieldName, operator, filterValue] =
        filterArray as unknown as FilterItem;

      if (InspectionsPageFields[fieldName] && idx === 0) {
        res[fieldName] = {
          // default value
          isSorted: false,
          sortType: SortModelTypeMap.desc,
          // override
          ...(res[fieldName] || {}),
          isFiltered: true,
          filters: [
            {
              filterType: FilterModelTypeMap[operator],
              filterValue: filterValue.toString(),
            },
          ],
        };
      }

      return;
    }

    if (Array.isArray(item)) {
      // Search
      // filter: [
      //   ["inspectionRef", "contains", "search test"], <= item
      //   "or",
      //   ["regNumber", "contains", "search test"],
      //   "or",
      //   ["vin", "contains", "search test"],
      //   ...
      // ]
      const isSearch =
        (filterArray[idx + 1] as unknown as string) === 'or' ||
        (filterArray[idx - 1] as unknown as string) === 'or';

      if (isSearch) {
        res.search = item[2].toString();
        return;
      }

      // SingleFilterAndSearch
      // filter: [
      //   ['vin', 'contains', '9999', columnIndex: 4, filterValue: '9999']
      //   'and'
      //   [ <====================================================================== item as Search
      //     ["inspectionRef", "contains", "search test"],
      //     "or",
      //     ["regNumber", "contains", "search test"],
      //     "or",
      //     ["vin", "contains", "search test"],
      //     ...
      //   ]
      // ]
      //
      // AND
      //
      // MultipleFilterAndSearch
      // filter: [
      //  [ <====================================================================== item as MultipleFilter
      //    ['vin', 'contains', '9999', columnIndex: 4, filterValue: '9999']
      //    'and'
      //    ['inspectionLocation', 'contains', 'BMW', columnIndex: 6, filterValue: 'BMW']
      //  ]
      //  'and'
      //  [ <====================================================================== item as Search
      //    ["inspectionRef", "contains", "search test"],
      //    "or",
      //    ["regNumber", "contains", "search test"],
      //    "or",
      //    ["vin", "contains", "search test"],
      //    ...
      //  ]
      // ]
      if (Array.isArray(item[0])) {
        // @ts-ignore
        item.forEach(addBodyFilters);
        return;
      }

      // MultipleFilter
      // filter: [
      //   ['vin', 'contains', '9999', columnIndex: 4, filterValue: '9999'] <= item
      //   'and'
      //   ['inspectionLocation', 'contains', 'BMW', columnIndex: 6, filterValue: 'BMW']
      // ]
      addBodyFilters(item[0], 0, item as unknown as Filter);
    }
  };

  // @ts-ignore
  filter?.forEach(addBodyFilters);

  // 'Awaiting Authorisation' status available only for users with Members role
  if (isMember) {
    const fieldName =
      InspectionsPageFields.inspectionStatus as keyof typeof InspectionsPageFields;

    return {
      ...res,
      [fieldName]: {
        ...(res[fieldName] || {}),
        isFiltered: true,
        filters: [
          {
            filterType: FilterModelTypeMap['='],
            filterValue: InspectionStatusMap['Awaiting Authorisation'],
          },
        ],
      },
    };
  }

  return res;
};

const setFiltersIntoURL = (body: GetInspectionsData) => {
  const { skip, take, search, ...fields } = body;

  const searchParams = new URLSearchParams();

  if (skip !== undefined) {
    searchParams.set('skip', skip.toString());
  }

  if (take !== undefined) {
    searchParams.set('take', take.toString());
  }

  if (search) searchParams.set('search', search.toString());

  Object.entries(fields).forEach(([key, value]) =>
    searchParams.set(key, JSON.stringify(value)),
  );

  const newRelativePathQuery = `${
    window.location.pathname
  }?${searchParams.toString()}`;

  window.history.pushState(null, '', newRelativePathQuery);
};

export const inspectionsCustomStoreLoad =
  ({ isMember }: { isMember: boolean }): CustomStoreOptions['load'] =>
  async (loadOptions) => {
    const { skip, take, filter, sort } = loadOptions;

    // if skip and take are undefined, it will load all entitites
    let body: GetInspectionsData = {
      skip,
      take,
    };

    body = addFiltering({ isMember, filter, body });

    if (Array.isArray(sort)) {
      body = addSorting({ sort: sort as [Sort], body });
    }

    setFiltersIntoURL(body);

    const response = await inspections.postInspectionsVehicles(body);

    return {
      data: response.data.entities,
      totalCount: response.data.totalCount,
      summary: undefined,
      groupCount: undefined,
    };
  };
