import { Document } from '@/types';

export const labelBackgroundColor = (color: string) => ({
  backgroundColor: color,
  visible: true,
});

export const isPagerVisible = (pageSize: number, itemsLength: number = 0) =>
  itemsLength > pageSize;

export const removeExtension = (filename: string) =>
  filename.replace(/\.[^/.]+$/, '');

export const downloadFile = (href: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = href;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const filterCustomerPreviewRestrictedItems = (document: Document) =>
  !document.isCustomerPreviewRestricted;

export const currencyFormatted = (
  price?: number,
  options?: Intl.NumberFormatOptions,
) => {
  if (typeof price !== 'number') return null;

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    ...(options || {}),
  }).format(price);
};

export const isValidDate = (dateString: string): boolean =>
  !Number.isNaN(Date.parse(dateString));

export const localDateFormatted = (date: string) =>
  new Date(date).toLocaleDateString('en-GB');

export const localDateTimeFormatted = (
  date: string,
  options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  } as Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat('en-GB', options).format(new Date(date));

export const localTimeFormatted = (
  date: string,
  options = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  } as Intl.DateTimeFormatOptions,
) => new Intl.DateTimeFormat('en-GB', options).format(new Date(date));

export const getDifferenceInMonthsFromDate = (from: string, to?: string) => {
  const fromDate = new Date(from);
  const toDate = to ? new Date(to) : new Date();

  const differenceBetweenMonths = toDate.getMonth() - fromDate.getMonth();
  const differenceBetweenYearsInMonths = 12 * (toDate.getFullYear() - fromDate.getFullYear());

  return differenceBetweenMonths + differenceBetweenYearsInMonths;
};

export const getOptions = <T, K extends keyof T>(
  data: T[] | undefined,
  key: K,
): Array<T[K]> => [...new Set(data?.map((item) => item[key]))];

export const getFormattedLabel = (key: string, searchValue = 'ImageFileUrl') =>
  key
    .replace(searchValue, '')
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const updateQueryParam = (paramName: string, paramValue: string) => {
  if (!paramValue) return;

  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(paramName, paramValue);

  const newRelativePathQuery = `${
    window.location.pathname
  }?${searchParams.toString()}`;

  window.history.pushState(null, '', newRelativePathQuery);
};

export const removeQueryParam = (paramName: string) => {
  const inspectionStatusSearchParams = new URLSearchParams(
    window.location.search,
  );

  inspectionStatusSearchParams.delete(paramName);

  let newRelativePathQuery = window.location.pathname;

  if (inspectionStatusSearchParams.toString())
    newRelativePathQuery += `?${inspectionStatusSearchParams.toString()}`;

  window.history.pushState(null, '', newRelativePathQuery);
};

export const sortDataByDate = <T>(data: T[] | undefined, key: keyof T): T[] =>
  data?.sort(
    (a, b) =>
      new Date(a[key] as unknown as string).getTime() -
      new Date(b[key] as unknown as string).getTime(),
  ) || [];

export const getDataByKey = <T>(
  data: T[] | undefined,
  key: keyof T,
  inspectionStatus: number,
) =>
  data?.find((item) => (item?.[key] as unknown as number) === inspectionStatus);
