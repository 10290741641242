import React, { FC, useCallback } from 'react';
import { DevButton } from '@/ui';
import { useTranslate } from '@/translations';
import { useNavigate } from 'react-router-dom';

export const BackButton: FC<{ linkTo?: string }> = ({ linkTo }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const handleNavigate = useCallback(
    () => (linkTo ? navigate(linkTo) : navigate(-1)),
    [linkTo, navigate],
  );

  return (
    <DevButton
      text={t('back')}
      icon="back"
      stylingMode="contained"
      onClick={handleNavigate}
    />
  );
};
