import React, { FC } from 'react';
import { Row } from '@/ui';
import { PageContainer } from '@/ui/components/Page';

import { Gallery } from 'react-photoswipe-gallery';
import { TireCard } from './components';
import { splitTireData } from './components/helpers';
import { InspectionPageTab } from '../types';

export const WheelsAndTyres: FC<InspectionPageTab> = ({ inspection }) => {
  const { frontLeft, frontRight, rearLeft, rearRight, spareTyre } =
    splitTireData(inspection);

  return (
    <PageContainer fluid>
      <Gallery>
        <Row xxl={4} lg={2} xs={1}>
          <TireCard tireData={frontLeft} />
          <TireCard tireData={frontRight} />
          <TireCard tireData={rearLeft} />
          <TireCard tireData={rearRight} />
          <TireCard tireData={spareTyre} />
        </Row>
      </Gallery>
    </PageContainer>
  );
};
