import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useInspectionsVehiclesParts,
  useUpdateInspectionsVehicleDamageParts,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { partCodeValidator } from '@/utils/validations';
import { AddDamagePartBody } from '@/types';
import { Button, Popup, NumberBox, Form, Row, Col } from '@/ui';
import { useTranslate } from '@/translations';
import { NumberBoxWrapper } from '@/modules/Inspection/components/tabs/Damage/components/CreateFlatRateCodePopup/CreateFlatRateCodePopup.styled';
import { queryClient } from '@/config/reactQuery';
import { QUERY_KEYS } from '@/api/queryKeys';

import { AddPartPopupProps } from './types';

export const AddDamagePartPopup: FC<AddPartPopupProps> = ({
  vehicleDamageId,
  vehicleDamageActionId,
  flatRateCode,
  isPopupVisible,
  togglePopup,
}) => {
  const { id: inspectionId } = useParams();
  const { t } = useTranslate();

  const [partCode, setPartCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<number>(0);

  const {
    refetch: refetchParts,
    isLoading: isLoadingParts,
    isRefetching: isRefetchingParts,
  } = useInspectionsVehiclesParts(partCode);

  const { mutateAsync: mutateAsyncDamageParts } =
    useUpdateInspectionsVehicleDamageParts();

  useEffect(() => {
    if (partCodeValidator(partCode)) {
      refetchParts().then((response) => {
        const partCodeData = response?.data?.entities[0];

        if (!partCodeData) return;

        setDescription(partCodeData.description);
        setCost(partCodeData.retailPrice);
      });
    }
  }, [partCode, refetchParts]);

  const onPartCodeChange = useCallback(
    ({ target: { value } }) => setPartCode(value),
    [],
  );

  const onDescriptionChange = useCallback(
    ({ target: { value } }) => setDescription(value),
    [],
  );

  const onQuantityChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    setQuantity(Number(value));
  };

  const onCostChange = ({
    event: {
      target: { value },
    },
  }: // eslint-disable-next-line
  any) => {
    // remove currency symbol and white spaces
    const formattedValue = value.slice(1).trim();
    setCost(Number(formattedValue));
  };

  const handleTogglePopup = useCallback(() => {
    setPartCode('');
    setQuantity(1);
    setDescription('');
    setCost(0);

    togglePopup();
  }, [togglePopup]);

  // eslint-disable-next-line
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const body: AddDamagePartBody = {
      vehicleDamageActionId,
      flatRateCode,
      partNumber: partCode,
      quantity,
      price: cost,
    };

    try {
      await mutateAsyncDamageParts(body);
      // refetch Damage Action (FR Code) (update costs)
      await queryClient.invalidateQueries([QUERY_KEYS.damageActions, vehicleDamageId]);
      // refetch Damages (update costs)
      await queryClient.invalidateQueries([QUERY_KEYS.damages, Number(inspectionId)]);
      // refetch Inspection (update costs)
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionsVehicle, Number(inspectionId)]);
    } catch (error) {
      console.log('handleSubmit: ', { error });
    } finally {
      handleTogglePopup();
    }
  };

  const isSubmitDisabled = isLoadingParts
    || isRefetchingParts
    || !flatRateCode
    || !partCode
    || Number.isNaN(Number(cost));

  return (
    <Popup
      visible={isPopupVisible}
      onHiding={handleTogglePopup}
      position="center"
      minWidth="320"
      maxWidth="400"
      height="auto"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="frCode">
          <Form.Label column sm="4">
            {t('frCode')}
          </Form.Label>
          <Col sm="8">
            <Form.Control value={flatRateCode} disabled />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="partCode">
          <Form.Label column sm="4">
            {t('code')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              value={partCode}
              onChange={onPartCodeChange}
              placeholder="11 digits, ex: 00003103414"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label column sm="4">
            {t('description')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="textarea"
              rows={5}
              value={description}
              onChange={onDescriptionChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="quantity">
          <Form.Label column sm="4">
            {t('quantity')}
          </Form.Label>
          <Col sm="8">
            <NumberBoxWrapper>
              <Form.Control
                as={NumberBox}
                min={1}
                max={100}
                format="#"
                value={quantity}
                onChange={onQuantityChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="cost">
          <Form.Label column sm="4">
            {t('cost')}
          </Form.Label>
          <Col sm="8">
            <NumberBoxWrapper>
              <Form.Control
                as={NumberBox}
                min={0}
                max={50_000}
                format={{
                  currency: 'GBP',
                  type: 'currency',
                  precision: 2,
                }}
                value={cost}
                onChange={onCostChange}
              />
            </NumberBoxWrapper>
          </Col>
        </Form.Group>

        <div className="mt-3 d-flex gap-2 justify-content-end">
          <Button
            type="submit"
            disabled={isSubmitDisabled}
          >
            {t('save')}
          </Button>
          <Button type="button" onClick={handleTogglePopup}>
            {t('cancel')}
          </Button>
        </div>
      </Form>
    </Popup>
  );
};
