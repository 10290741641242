export const routes = {
  root: '/',
  storage: '/storage',
  transportSummary: '/transport-movement',
  vessels: '/vessel',
  vin: '/vin',
  order: '/order',
  orderAdmin: '/order/admin',
  shippingPlan: '/shipping-plan',
  rolesAdmin: '/rolesadmin',
  manageUser: '/manage',
  manageUsers: '/manage-users',
  manageGBAUsers: '/manage-gba-users',
  manageTMSUsers: '/manage-tms-users',
  pipeline: '/pipeline',
  systemAdministration: '/admin',
  documentManagement: '/document-management',
  adminDocumentManagement: '/admin/document-management',
  uploadFile: '/admin/:movementId/upload-file',
  tenantManagement: '/tenant-management',
  inspection: '/inspection/:id',
  inspections: '/inspections',
  passport: '/passport',
  pts: '/pts',
  damage: '/admin/damage/:id',
  report: '/report/:id',
};

export const systemAdministrationRoutes = {
  locations: `${routes.systemAdministration}/locations`,
  transportUnits: `${routes.systemAdministration}/transportation`,
  companies: `${routes.systemAdministration}/companies`,
  shippingPlan: `${routes.systemAdministration}/shipping-plan`,
  tenantCustomization: `${routes.systemAdministration}/tenant-customization`,
  checklist: `${routes.systemAdministration}/checklist`,
  damages: `${routes.systemAdministration}/damages`,
  damage: `${routes.systemAdministration}/damage`,
};

export const paths = {
  locations: 'locations',
  transportation: 'transportation',
  companies: 'companies',
  shippingPlan: 'shipping-plan',
  uploadFile: 'upload-file',
  tenantCustomization: 'tenant-customization',
  checklist: 'checklist',
  inspections: 'inspections',
  inspection: 'inspection',
  damages: 'damages',
  damage: 'damage/:id',
  mechanical: 'mechanical',
  mechanicalApproved: 'mechanical/approved',
  vehicle: 'vehicle',
  vehicleApproved: 'vehicle/approved',
};
