import { useMutation, useQuery } from 'react-query';

import { queryClient } from '@/config/reactQuery';

import { QUERY_KEYS } from '../../queryKeys';
import { users } from '../Users';

interface QueryOptions {
  enabled?: boolean;
}

export const useUsers = (userId?: number) =>
  useQuery(
    [QUERY_KEYS.users, userId],
    async () => (await users.getUsers(userId)).data,
  );

export const useGBAUsers = (options?: QueryOptions) =>
  useQuery(
    QUERY_KEYS.gbaUsers,
    async () => (await users.getGBAUsers()).data,
    options,
  );

export const useCreateUser = () =>
  useMutation(users.createUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useCreateGBAUser = () =>
  useMutation(users.createGBAUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.gbaUsers);
    },
  });

export const useCreateUserInTenant = () =>
  useMutation(users.createUserInTenant, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useUpdateUser = () => useMutation(users.updateUser);

export const useUpdateGBAUser = () => useMutation(users.updateGBAUser);

export const useDeleteUser = () =>
  useMutation(users.deleteUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useDeleteGBAUser = () =>
  useMutation(users.deleteGBAUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useInviteUser = () =>
  useMutation(users.inviteUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useInviteGBAUser = () =>
  useMutation(users.inviteGBAUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useTenantUsers = (tenantId: number, options?: QueryOptions) =>
  useQuery(
    [QUERY_KEYS.users, tenantId],
    async () => (await users.getTenantUsers(tenantId)).data,
    options,
  );

export const useTenantGBAUsers = (tenantId: number, options?: QueryOptions) =>
  useQuery(
    [QUERY_KEYS.users, tenantId],
    async () => (await users.getTenantGBAUsers(tenantId)).data,
    options,
  );

export const useAssignGBAUserToTenant = () =>
  useMutation(users.assignGBAUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
    onError: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useUnassignGBAUserToTenant = () =>
  useMutation(users.unassignGBAUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.users);
    },
  });

export const useTMSUsers = (tmsUserId?: number) =>
  useQuery(
    [QUERY_KEYS.tmsUsers, tmsUserId],
    async () => (await users.getTMSUsers(tmsUserId)).data,
  );

export const useCreateTMSUser = () =>
  useMutation(users.createTMSUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.tmsUsers);
    },
  });

export const useDeleteTMSUser = () =>
  useMutation(users.deleteTMSUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.tmsUsers);
    },
  });

export const useUpdateTMSUser = () =>
  useMutation(users.updateTMSUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEYS.tmsUsers);
    },
  });
