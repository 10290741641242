import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { useTranslate } from '@/translations';
import { Col, Row, Button as BootstrapButton } from '@/ui';
import {
  currencyFormatted,
  getDifferenceInMonthsFromDate,
} from '@/utils/helpers';
import { TranslationKey } from '@/translations/types';

import { SalesDecisionCardProps } from './types';
import { StyledRow, Text } from './Card.styled';

// marks values for different sliders
const eighteen = 18;
const twentyThousands = 20_000;
const thirtyFiveThousands = 35_000;
const fiftyThousands = 50_000;

// floated values, Auto NOK is 90% of max value, just to make Auto NOK mark in one line with other sliders
const ageMaxValue = (eighteen / 90) * 100;
const mileageMaxValue = (fiftyThousands / 90) * 100;
const repairCostMaxValue = (twentyThousands / 90) * 100;

export const Card: FC<SalesDecisionCardProps> = ({ inspection }) => {
  const { t } = useTranslate();
  const [status, setStatus] = useState('Eligible');

  const ageDefaultValue = getDifferenceInMonthsFromDate(
    inspection.inspectionDateTime,
  );
  const mileageDefaultValue = inspection.mileage;
  const repairCostDefaultValue = inspection.finalCost;
  const maintenanceHistory = true;
  const chiptuningDetected = true;

  const getCategoryLabel = useCallback(
    (currentValue: number, maxValue: number): ReactElement => {
      if (currentValue > maxValue) {
        return (
          <Text color="red" textAlign="center">
            {t('autoNOK')}
          </Text>
        )
      }

      if (currentValue > maxValue * 0.7) {
        // if currentValue between 70% and 100%
        return (
          <Text fontWeight="bold" textAlign="center">
            {t('manualDecision')}
          </Text>
        )
      }

      return (
        <Text color="green" textAlign="center">
          {t('autoOK')}
        </Text>
      );
    },
    [t],
  );

  const sliderConfigs = useMemo(
    () => [
      {
        title: 'age',
        min: 0,
        max: ageMaxValue,
        defaultValue: ageDefaultValue,
        marks: {
          [ageDefaultValue]: {
            label: `${ageDefaultValue} ${t(
              ageDefaultValue === 1 ? 'month' : 'months',
            )}`,
            style: {
              backgroundColor: 'white', // do white background to overlap other marks text
              textWrap: 'nowrap',
              ...(ageDefaultValue > ageMaxValue && { left: '100%' }),
              ...(ageDefaultValue < ageMaxValue / 2 && { transform: 'none' }), // avoiding text overflow
            },
          },
          [eighteen]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
            },
          },
        },
        styles: {
          track: {
            backgroundColor: ageDefaultValue < eighteen ? 'green' : 'red',
          },
        },
      },
      {
        title: 'mileage',
        min: 0,
        max: mileageMaxValue,
        defaultValue: mileageDefaultValue,
        marks: {
          [mileageDefaultValue]: {
            label: `${mileageDefaultValue} km`,
            style: {
              backgroundColor: 'white', // do white background to overlap other marks text
              textWrap: 'nowrap',
              ...(mileageDefaultValue > mileageMaxValue && { left: '100%' }),
              ...(mileageDefaultValue < mileageMaxValue * 0.1 && {
                transform: 'none',
              }), // avoiding text overflow
            },
          },
          [thirtyFiveThousands]: {
            label: `${thirtyFiveThousands} km`,
          },
          [fiftyThousands]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
            },
          },
        },
        styles: {
          track: {
            backgroundColor:
              (mileageDefaultValue < thirtyFiveThousands && 'green') ||
              (mileageDefaultValue < fiftyThousands && 'yellow') ||
              'red',
          },
        },
      },
      {
        title: 'repairCost',
        min: 0,
        max: repairCostMaxValue,
        defaultValue: repairCostDefaultValue,
        marks: {
          [repairCostDefaultValue]: {
            label: currencyFormatted(repairCostDefaultValue, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            style: {
              backgroundColor: 'white', // do white background to overlap other marks text
              textWrap: 'nowrap',
              ...(repairCostDefaultValue > repairCostMaxValue && {
                left: '100%',
              }),
              ...(repairCostDefaultValue < repairCostMaxValue / 2 && {
                transform: 'none',
              }), // avoiding text overflow
            },
          },
          [twentyThousands]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
            },
          },
        },
        styles: {
          track: {
            backgroundColor:
              repairCostDefaultValue < twentyThousands ? 'green' : 'red',
          },
        },
      },
    ],
    [t, ageDefaultValue, mileageDefaultValue, repairCostDefaultValue],
  );

  return (
    <Row>
      <Col xxl={6} sm={12}>
        <Row className="mb-3">
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '18px',
              marginBottom: '8px',
            }}
          >
            {t('statusColon')} {status}
          </p>
        </Row>

        {sliderConfigs.map(
          ({ title, min, max, defaultValue, marks, styles }) => (
            <div key={title} className="mb-2 pb-3">
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  marginBottom: '0px',
                }}
              >
                {t(title as TranslationKey)}
              </p>
              <Slider
                id={`slider__${title}`}
                min={min}
                max={max}
                defaultValue={defaultValue}
                marks={marks}
                step={1}
                disabled
                style={{
                  backgroundColor: 'initial', // override gray background on .rc-slider-disabled
                }}
                styles={{
                  rail: {
                    backgroundColor: 'initial',
                    border: '1px solid black',
                    height: '8px',
                  },
                  track: {
                    height: '8px',
                    ...(styles.track || {}),
                  },
                  handle: {
                    display: 'none',
                  },
                }}
                dotStyle={{
                  width: '1px',
                  borderRadius: 0,
                  backgroundColor: 'black',
                  border: '1px solid black',
                  cursor: 'auto',
                  top: 0,
                }}
                activeDotStyle={{
                  border: 'none',
                  background: 'none',
                }}
              />
            </div>
          ),
        )}
      </Col>
      <Col xxl={6} sm={12}>
        <StyledRow>
          <Col sm={4}>
            <Text>{t('maintenanceHistory')}</Text>
          </Col>
          <Col sm={4}>
            <Text textAlign="center">{t('yes')}</Text>
          </Col>
          <Col sm={4}>
            {/* mock label values, 20 for autoNOK, 0 for autoOK */}
            {getCategoryLabel(maintenanceHistory ? 0 : 20, 10)}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col sm={4}>
            <Text>{t('chiptuningDetected')}</Text>
          </Col>
          <Col sm={4}>
            <Text textAlign="center">{chiptuningDetected ? t('yes') : t('no')}</Text>
          </Col>
          <Col sm={4}>
            {/* mock label values, 20 for autoNOK, 0 for autoOK */}
            {getCategoryLabel(chiptuningDetected ? 20 : 0, 10)}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col sm={4}>
            <Text>{t('age')}</Text>
          </Col>
          <Col sm={4}>
            <Text textAlign="center">
              {ageDefaultValue} {t(ageDefaultValue === 1 ? 'month' : 'months')}
            </Text>
          </Col>
          <Col sm={4}>
            {getCategoryLabel(ageDefaultValue, eighteen)}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col sm={4}>
            <Text>{t('mileage')}</Text>
          </Col>
          <Col sm={4}>
            <Text textAlign="center">{mileageDefaultValue} km</Text>
          </Col>
          <Col sm={4}>
            {getCategoryLabel(mileageDefaultValue, fiftyThousands)}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col sm={4}>
            <Text>{t('repairCost')}</Text>
          </Col>
          <Col sm={4}>
            <Text textAlign="center">
              {currencyFormatted(repairCostDefaultValue, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Text>
          </Col>
          <Col sm={4}>
            {getCategoryLabel(repairCostDefaultValue, twentyThousands)}
          </Col>
        </StyledRow>
        <Row className="mt-3">
          <BootstrapButton
            variant="success"
            onClick={() => setStatus('Eligible')}
          >
            {t('approveForRepair')}
          </BootstrapButton>
        </Row>
        <Row className="mt-3">
          <BootstrapButton
            variant="danger"
            onClick={() => setStatus('Not eligible')}
          >
            {t('rejectForRepair')}
          </BootstrapButton>
        </Row>
      </Col>
    </Row>
  );
};
