export enum AppFeatures {
  Pipeline = 'Pipeline',
  Storage = 'Storage',
  TransportMovement = 'TransportMovement',
  Vessel = 'Vessel',
  Order = 'Order',
  ShippingPlan = 'ShippingPlan',
  VinTracking = 'VinTracking',
  Passport = 'Passport',
  StockSheet = 'StockSheet',
  DocumentManagement = 'DocumentManagement',
  Inspections = 'Inspections',
}
