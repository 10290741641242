import styled from 'styled-components';

import { Row } from '@/ui';

export const StyledRow = styled(Row)`
  margin-bottom: 12px;
  border-bottom: ${({ theme }) => `1px solid ${theme.gray}`};
`;

export const Text = styled.p<{
  color?: string;
  fontWeight?: string;
  textAlign?: string;
}>`
  white-space: nowrap;
  margin-bottom: 4px;
  color: ${({ theme, color }) => color || theme.black};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
`;
