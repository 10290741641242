import React, { FC, useCallback, useState } from 'react';

import {
  Column,
  DataGrid,
  Export,
  LoadPanel,
  MasterDetail,
  Pager,
  Paging,
  FilterRow,
  RowPreparedEvent,
} from '@/ui';
import { StyledLink, StyledLinkContainer } from '@/ui/globalStyles';
import { faDownload, Icon } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { currencyFormatted, isPagerVisible } from '@/utils/helpers';
import {
  useUpdateInspectionsVehicleDamages,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { ActionsPopup } from '@/modules/Inspection/components/tabs/Damage/components/ActionsPopup';
import { useTheme } from 'styled-components';
import { useDataGridExpandedState } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridExpandedState';
import { useDataGridScroll } from '@/modules/Inspection/components/tabs/Damage/components/useDataGridScroll';

import { DamageMasterDetail } from './MasterDetail';
import {
  AuthorizationStatus,
  DamageDataGridProps,
} from './types';

const PAGE_SIZE = 6;
const INITIAL_VALUES = {
  id: null,
  status: AuthorizationStatus.Default,
};

export const DamageDataGrid: FC<DamageDataGridProps> = ({ dataSource }) => {
  const { t } = useTranslate();
  const { lightGreen, lightRed } = useTheme();
  const { expandedRows, onRowExpanded, onRowCollapsed } =
    useDataGridExpandedState();
  // leave this hook here, to avoid data clearing when re-rendering MasterDetail
  const masterDetailsDataGridProps = useDataGridExpandedState();
  const { ref } = useDataGridScroll({
    dataSource,
    expandedRows,
    // always scroll this dataGrid, cause it's mounted only once and scroll will be fired on each dataSource change
    // scroll here once, instead of scrolling 3+ times in MasterDetail data grid
    shouldScroll: true,
  });

  // TODO: replace to imageFileUrls[] ??? check with BE, don't see this field now
  const onRenderActionsCell = useCallback(
    ({ data: { imageFileUrl, fileName } }) =>
      imageFileUrl ? (
        <a href={imageFileUrl} download={fileName} aria-label="Download">
          <Icon icon={faDownload} />
        </a>
      ) : null,
    [],
  );

  const [comment, setComment] = useState<string>('');
  const [isPopupVisible, setPopupVisibility] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<{
    id: number | null;
    status: AuthorizationStatus;
  }>(INITIAL_VALUES);

  const { mutate: updateVehicleDamageMutate } =
    useUpdateInspectionsVehicleDamages();

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const handleAuthorizationStatus = useCallback(
    (id: number, status: AuthorizationStatus) => {
      setAuthorization({
        id,
        status,
      });
      togglePopup();
    },
    [togglePopup],
  );

  const handleSaveClick = () => {
    if (!authorization.id) return;

    updateVehicleDamageMutate({
      id: authorization.id,
      authorizationNotes: {
        isChanged: true,
        value: comment,
      },
      authorizationStatus: {
        isChanged: true,
        value: authorization.status,
      },
      severityDesc: {
        isChanged: false,
      },
      repairActionDesc: {
        isChanged: false,
      },
    });

    setComment('');
    togglePopup();
  };

  const handleCancelClick = () => {
    setComment('');
    setAuthorization(INITIAL_VALUES);
    togglePopup();
  };

  const getColorForAuthorizationStatus = ({
    rowType,
    data,
    rowElement,
  }: RowPreparedEvent) => {
    if (rowType !== 'data') return;

    const status = data?.authorizationStatus;
    const colorMap: { [key: string]: string } = {
      Approved: lightGreen,
      Rejected: lightRed,
    };
    const color = colorMap[status];

    if (color) {
      /* eslint-disable no-param-reassign */
      rowElement.style.backgroundColor = color;
    }
  };

  const renderCell = useCallback(
    ({ data }) => {
      const { id } = data;

      return (
        <StyledLinkContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledLink
              onClick={() =>
                handleAuthorizationStatus(id, AuthorizationStatus.Approved)
              }
            >
              {t('approve')}
            </StyledLink>
            <StyledLink
              onClick={() =>
                handleAuthorizationStatus(id, AuthorizationStatus.Rejected)
              }
            >
              {t('reject')}
            </StyledLink>
          </div>
        </StyledLinkContainer>
      );
    },
    [
      handleAuthorizationStatus,
      t,
    ],
  );

  return (
    <>
      <ActionsPopup
        isPopupVisible={isPopupVisible}
        togglePopup={togglePopup}
        comment={comment}
        setComment={setComment}
        handleSaveClick={handleSaveClick}
        handleCancelClick={handleCancelClick}
        authorizationStatus={authorization.status}
      />

      <DataGrid
        id="gridContainer"
        dataSource={dataSource}
        columnHidingEnabled
        allowColumnResizing
        columnResizingMode="widget"
        columnAutoWidth
        showRowLines
        showBorders
        repaintChangesOnly
        width="100%"
        onRowPrepared={getColorForAuthorizationStatus}
        ref={ref}
        onRowExpanded={onRowExpanded}
        onRowCollapsed={onRowCollapsed}
      >
        <LoadPanel enabled />
        <MasterDetail
          enabled
          render={({ data }) => (
            <DamageMasterDetail
              vehicleDamageId={data.id}
              image={data.imageFileUrl}
              note={data.note}
              authorizationNotes={data.authorizationNotes}
              masterDetailsDataGridProps={masterDetailsDataGridProps}
            />
          )}
        />
        <Paging pageSize={PAGE_SIZE} />
        <Pager
          showInfo
          showNavigationButtons
          visible={isPagerVisible(PAGE_SIZE, dataSource?.length)}
        />
        <FilterRow visible />
        <Export enabled />

        <Column
          dataField="damageAreaDesc"
          caption={t('location')}
          width={150}
        />
        <Column dataField="damagePartDesc" caption={t('partComp')} />
        <Column dataField="damageType" caption={t('damageType')} />
        <Column dataField="sector" caption={t('sector')} />
        <Column dataField="severityDesc" caption={t('severity')} />
        <Column
          dataField="repairActionDesc"
          caption={t('repairMethod')}
          width={160}
        />
        <Column
          dataField="internalGradingPoints"
          caption={t('internalGrade')}
        />
        <Column dataField="workCenterDesc" caption={t('workCentre')} />
        <Column dataField="responsibilityDesc" caption={t('responsibility')} />
        <Column dataField="gradePoints" caption={t('gradePoints')} />
        <Column
          dataField="lineCost"
          caption={t('lineCost')}
          format={currencyFormatted}
        />
        <Column
          dataField="imageFileUrl"
          caption={null}
          allowSorting={false}
          allowFiltering={false}
          cellRender={onRenderActionsCell}
          alignment="center"
          width={40}
        />
        <Column
          dataField="actions"
          caption={null}
          cellRender={renderCell}
          alignment="center"
          allowSorting={false}
          allowEditing={false}
          allowSearch={false}
          allowFiltering={false}
          allowExporting={false}
          showEditorAlways={false}
          width={140}
        />
      </DataGrid>
    </>
  );
};
