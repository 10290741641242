import React, { FC } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { Carousel, Image } from '@/ui';
import { getFormattedLabel } from '@/utils/helpers';

import { ImageItem } from '@/ui/components/ImagesLightBox/types';
import { LightBoxItem } from '@/ui/components/ImagesLightBox/ImagesLightBox';
import { Gallery } from 'react-photoswipe-gallery';
import { InspectionPageTab } from '../types';

export const Images: FC<InspectionPageTab> = ({ inspection }) => {
  const {
    frontImageFileUrl,
    rearImageFileUrl,
    nearsideImageFileUrl,
    offsideImageFileUrl,
    chargeLevelImageFileUrl,
    frontResizedImageUrl,
    rearResizedImageUrl,
    nearsideResizedImageUrl,
    offsideResizedImageUrl,
    chargeLevelResizedImageUrl,
  } = inspection;

  type ExtendedImageItem = ImageItem & { key: string };

  const images: ExtendedImageItem[] = [
    {
      id: 1,
      imageFileUrl: frontImageFileUrl,
      thumbnailImageUrl: frontResizedImageUrl,
      key: 'frontImageFileUrl',
    },
    {
      id: 2,
      imageFileUrl: rearImageFileUrl,
      thumbnailImageUrl: rearResizedImageUrl,
      key: 'rearImageFileUrl',
    },
    {
      id: 3,
      imageFileUrl: nearsideImageFileUrl,
      thumbnailImageUrl: nearsideResizedImageUrl,
      key: 'nearsideImageFileUrl',
    },
    {
      id: 4,
      imageFileUrl: offsideImageFileUrl,
      thumbnailImageUrl: offsideResizedImageUrl,
      key: 'offsideImageFileUrl',
    },
    {
      id: 5,
      imageFileUrl: chargeLevelImageFileUrl,
      thumbnailImageUrl: chargeLevelResizedImageUrl,
      key: 'chargeLevelImageFileUrl',
    },
  ];

  const renderItem = (imageItem: ExtendedImageItem) => (
    <Image
      src={imageItem.thumbnailImageUrl}
      alt={getFormattedLabel(imageItem.key)}
      rounded
      style={{ maxHeight: '50vh' }}
      loading="lazy"
    />
  );

  return (
    <PageContainer fluid interval={null}>
      <div className="d-flex justify-content-center">
        <Gallery>
          <Carousel slide={false}>
            {images.map((image) => (
              <Carousel.Item key={image.thumbnailImageUrl}>
                <LightBoxItem
                  key={image.id}
                  image={image}
                  itemRenderer={() => renderItem(image)}
                />
                <Carousel.Caption>
                  <h3>{getFormattedLabel(image.key)}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Gallery>
      </div>
    </PageContainer>
  );
};
