import React, { FC } from 'react';
import { getValueOrNA } from '@/ui/components/NotApplicable';
import { useTranslate } from '@/translations';

import { fieldRenderers } from './helpers';
import { Field } from '../Field';
import { InspectionCardProps } from './types';

export const Card: FC<InspectionCardProps> = ({ data, fields, className }) => {
  const { t } = useTranslate();

  return (
    <>
      {Object.entries(fields).map(([field, label]) => {
        const value = fieldRenderers[field]
          ? fieldRenderers[field](data, t)
          : getValueOrNA(field)({ data });

        return (
          <Field key={field} label={label} value={value} className={className} />
        );
      })}
    </>
  );
};
