import React from 'react';
import { useParams } from 'react-router-dom';

import { useInspectionsReportsInitial } from '@/api/Inspections';
import { Loader } from '@/ui/components/Loader';
import { ReportInitial } from '@/types';

import { VehicleAppraisalReport as VehicleAppraisalReportMarkup } from '../components/VehicleAppraisalReport';

export const VehicleAppraisalReport = () => {
  const { id } = useParams();

  const { isLoading: isReportsInitialDataLoading, data: reportsInitialData } =
    useInspectionsReportsInitial(Number(id));

  if (isReportsInitialDataLoading) return <Loader />;

  const initialReport = reportsInitialData?.entities?.[0] as ReportInitial;
  const vehicleDamages = initialReport?.returnInspectionDamageDetails;
  const imageUrls = vehicleDamages.reduce((acc, damage) => [...acc, ...damage.imageFileUrls], [] as string[]);

  const vehicleData = {
    regNumber: initialReport.regNumber,
    model: initialReport.model,
    gradeScore: initialReport.finalGradeScore,
    colour: initialReport.colour,
    mileage: initialReport.mileage ?? initialReport.returnInspectionOdometer?.mileage,
    vin: initialReport.vin,
    inspectionDateTime: initialReport.inspectionDateTime,
  };

  return (
    <VehicleAppraisalReportMarkup
      vehicleData={vehicleData}
      vehicleDamages={vehicleDamages}
      imageUrls={imageUrls}
    />
  )
}
