import React, { FC, useCallback, useState } from 'react';

import { FunctionalFeature } from '@/types';
import {
  useAssignGBAUserToTenant,
  useCreateUserInTenant,
  useDeleteUser,
  useGBAUsers,
  useInviteGBAUser,
  useInviteUser,
  useTenantGBAUsers,
  useTenantUsers,
  useUnassignGBAUserToTenant,
} from '@/api/Users';
import { useInspectionsVehiclesInspectors } from '@/api/Inspections';
import { useGBARoles, useRoles } from '@/api/Roles';
import {
  useTenantFunctionalFeatures,
  useToggleDisableFunctionalFeatures,
} from '@/api/FunctionalFeatures';
import { TabPanel, TabPanelItem } from '@/ui';
import { AddUserData } from '@/modules/TenantManagement/components/tabs/Users/types';

import { UsersTab } from './Users/Users';
import { FeaturesTab } from './Features/Features';
import { formatCreateUserData } from './helpers';
import { GBAUsersTab } from './GBAUsers/GBAUsers';

const tabNames = ['GBA Users', 'Users', 'Features'];

export const MasterDetail: FC<{ tenantId: number }> = ({ tenantId }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { data: tenantGBAUsersData } = useTenantGBAUsers(tenantId, {
    enabled: selectedTabIndex === 0,
  });
  const { data: tenantUsersData } = useTenantUsers(tenantId, {
    enabled: selectedTabIndex === 1,
  });
  const { data: inspectorsData } = useInspectionsVehiclesInspectors({
    enabled: [0, 1].includes(selectedTabIndex),
  });
  const { data: tenantFeaturesData } = useTenantFunctionalFeatures(tenantId, {
    enabled: selectedTabIndex === 2,
  });

  const { data: gbaUsers } = useGBAUsers({
    enabled: selectedTabIndex === 0,
  });

  const { data: gbaRolesData } = useGBARoles({
    enabled: selectedTabIndex === 0,
  });
  const { data: rolesData } = useRoles({
    enabled: selectedTabIndex === 1,
  });

  const { mutate: updateFeatureMutate } = useToggleDisableFunctionalFeatures();

  const { mutate: createUserMutate } = useCreateUserInTenant();
  const { mutate: deleteUserMutate } = useDeleteUser();
  const { mutate: inviteUserMutate } = useInviteUser();

  const { mutate: inviteGBAUserMutate } = useInviteGBAUser();
  const { mutate: assignGBAUserMutate } = useAssignGBAUserToTenant();
  const { mutate: unassignGBAUserMutate } = useUnassignGBAUserToTenant();

  const handleTabSelectionChanged = useCallback((e) => {
    setSelectedTabIndex(tabNames.indexOf(e.addedItems[0].title));
  }, []);

  const handleUpdateFeature = useCallback(
    (
      { isEnabled }: Partial<FunctionalFeature>,
      { functionalFeature: { id } }: FunctionalFeature,
    ) => {
      updateFeatureMutate({
        tenantId,
        isEnabled: !!isEnabled,
        featureId: id,
      });
    },
    [tenantId, updateFeatureMutate],
  );

  const handleCreateUser = useCallback(
    (data: AddUserData) => {
      const formattedData = formatCreateUserData(data);
      createUserMutate({ ...formattedData, tenantId });
    },
    [createUserMutate, tenantId],
  );

  return (
    <TabPanel
      selectedIndex={selectedTabIndex}
      onSelectionChanged={handleTabSelectionChanged}
    >
      <TabPanelItem
        title={tabNames[0]}
        render={() => (
          <GBAUsersTab
            tenantId={tenantId}
            roles={gbaRolesData?.entities}
            users={tenantGBAUsersData?.entities}
            gbaUsers={gbaUsers?.entities}
            inspectors={inspectorsData?.entities}
            onAssignUser={assignGBAUserMutate}
            onUnassignUser={unassignGBAUserMutate}
            onInviteUser={inviteGBAUserMutate}
          />
        )}
      />
      <TabPanelItem
        title={tabNames[1]}
        render={() => (
          <UsersTab
            roles={rolesData?.entities}
            users={tenantUsersData?.entities}
            inspectors={inspectorsData?.entities}
            onAddUser={handleCreateUser}
            onDeleteUser={deleteUserMutate}
            onInviteUser={inviteUserMutate}
          />
        )}
      />
      <TabPanelItem
        title={tabNames[2]}
        render={() => (
          <FeaturesTab
            features={tenantFeaturesData?.entities}
            onFeatureUpdate={handleUpdateFeature}
          />
        )}
      />
    </TabPanel>
  );
};

export default MasterDetail;
