import { AxiosPromise } from 'axios';
import {
  PipelineGraphData,
  PipelineLocationsData,
  PipelinesData,
} from '@/types';
import { StatusDetailsData } from '@/types/Pipelines/StatusDetails';
import { Core, PIPELINES, PIPELINES_GRAPH, PIPELINES_LOCATIONS, PIPELINES_STATUS } from '../Core';

type GetPipelines = (
  locationId: string | undefined,
) => AxiosPromise<PipelinesData>;
type GetLocations = () => AxiosPromise<PipelineLocationsData>;
type GetGraph = () => AxiosPromise<PipelineGraphData>;
type GetStatuses = (locationId: string | undefined,) => AxiosPromise<StatusDetailsData>

class Pipelines extends Core {
  getPipelines: GetPipelines = async (locationId?: string) =>
    this.get(PIPELINES(locationId));

  getLocations: GetLocations = async () => this.get(PIPELINES_LOCATIONS());

  getGraph: GetGraph = async () => this.get(PIPELINES_GRAPH());

  getStatuses: GetStatuses = async (locationId?: string) => this.get(PIPELINES_STATUS(locationId));
}

export const pipelines = new Pipelines();
