import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  useInspectionsVehiclesSummaries,
  useInspectionsVehiclesNotes,
  useInspectionsVehiclesMechanicalsNotes,
} from '@/api/Inspections';
import { Col, Row, Card } from '@/ui';
import { PageContainer } from '@/ui/components/Page';
import { Loader } from '@/ui/components/Loader';
import { useTranslate } from '@/translations';

import { Gallery } from 'react-photoswipe-gallery';
import { LightBoxItem } from '@/ui/components/ImagesLightBox/ImagesLightBox';
import { ImageItem } from '@/ui/components/ImagesLightBox/types';
import { InspectionTabCard } from '../../InspectionTabCard';
import { InspectionPageTab } from '../types';

export const Inspection: FC<InspectionPageTab> = ({ inspection }) => {
  const { t } = useTranslate();
  const { id } = useParams();
  const inspectionNotes = useInspectionsVehiclesNotes(Number(id));
  const mechanicalsNotes = useInspectionsVehiclesMechanicalsNotes(Number(id));
  const summaries = useInspectionsVehiclesSummaries(Number(id));

  if (
    summaries.isLoading ||
    inspectionNotes.isLoading ||
    mechanicalsNotes.isLoading ||
    !summaries.data ||
    !inspectionNotes.data ||
    !mechanicalsNotes.data
  )
    return <Loader />;

  const { id: _inspectionNoteId, ...inspectionNote } =
    inspectionNotes.data.entities[0] || {};
  const { id: _mechanicalNoteId, ...mechanicalNote } =
    mechanicalsNotes.data.entities[0] || {};
  const { id: _summaryId, ...summary } = summaries.data.entities[0] || {};

  const renderItem = (imageItem: ImageItem) => (
    <Card.Img
      className="border w-auto mw-100 m-1"
      src={imageItem.thumbnailImageUrl}
      loading="lazy"
    />
  );

  const imageItem = {
    id: 0,
    imageFileUrl: inspection.odometerImageFileName,
    thumbnailImageUrl: inspection.odometerResizedImageUrl,
  };

  return (
    <PageContainer fluid>
      <Gallery>
        <Row xxl={4} lg={2} xs={1}>
          <Col className="mb-3 mb-xxl-0">
            <Card>
              <Card.Header className="text-muted fw-bold">
                {t('odometer')}
              </Card.Header>
              <Card.Body>
                <Card.Text as="div" className="dx-field m-0">
                  <div className="dx-field-label">
                    {inspection.mileage} {t('miles')}
                  </div>
                </Card.Text>
                <LightBoxItem
                  image={imageItem}
                  itemRenderer={() => renderItem(imageItem)}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col className="mb-3 mb-xxl-0">
            <InspectionTabCard
              header={t('inspectionNotes')}
              data={inspectionNote}
            />
          </Col>
          <Col className="mb-3 mb-xxl-0">
            <InspectionTabCard
              header={t('mechanicalInspectionNotes')}
              data={mechanicalNote}
            />
          </Col>
          <Col className="mb-3 mb-xxl-0">
            <InspectionTabCard header={t('summary')} data={summary} />
          </Col>
        </Row>
      </Gallery>
    </PageContainer>
  );
};
