import { Inspection, Damage } from '@/types';

export type DamageDataGridDataSource = Damage;

export interface DamageDataGridProps {
  inspection: Inspection;
  dataSource: DamageDataGridDataSource[];
}

export enum AuthorizationStatus {
  Default,
  Approved,
  Rejected,
}
