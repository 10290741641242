import { AxiosPromise } from 'axios';
import {
  AddDamageActionBody,
  AddDamagePartBody,
  UpdateInspectionStatus,
  UpdateInspectionStatusResponse,
  InspectionsData,
  GetInspectionsData,
  DamagesImagesData,
  ConditionsData,
  DamagesData,
  CreateDamageLineBody,
  UpdateDamageLineBody,
  DamageActionData,
  DamagePartData,
  RegularDamagePartData,
  PartData,
  FrCodeData,
  FlatRate,
  InspectorData,
  NotesData,
  UpdateNote,
  UpdateDamage,
  LogsData,
  DynamicOperationsData as ChecklistDynamicOperationsData,
  ElectricHybridData as ChecklistElectricHybridData,
  EngineRunningData as ChecklistEngineRunningData,
  InteriorData as ChecklistInteriorData,
  SummariesData,
  MechanicalsNotesData,
  MechanicalsWeelsData,
  ReportInitialData,
  DamageCodesData,
} from '@/types';
import {
  Core,
  INSPECTIONS_VEHICLES,
  INSPECTIONS_VEHICLE,
  INSPECTIONS_VEHICLE_DAMAGE,
  INSPECTIONS_VEHICLE_DAMAGES,
  INSPECTIONS_VEHICLES_DAMAGES_LINES,
  INSPECTIONS_VEHICLES_DAMAGES_LINES_BY_ID,
  INSPECTIONS_VEHICLE_DAMAGE_ACTIONS_BY_ID,
  INSPECTIONS_VEHICLE_DAMAGE_PARTS_BY_ID,
  INSPECTIONS_VEHICLE_DAMAGE_PARTS,
  INSPECTIONS_VEHICLE_DAMAGE_ACTIONS,
  INSPECTIONS_VEHICLES_PARTS,
  INSPECTIONS_VEHICLES_DAMAGE_ACTIONS_BY_FLAT_RATE_CODE,
  INSPECTIONS_VEHICLES_DAMAGE_PARTS_ID,
  DELETE_INSPECTIONS_VEHICLES_DAMAGE_ACTIONS_BY_ID,
  INSPECTIONS_CREATE_FLAT_RATE_CODE,
  INSPECTIONS_VEHICLES_INSPECTORS,
  INSPECTIONS_VEHICLES_ID_NOTES,
  INSPECTIONS_VEHICLES_NOTES,
  INSPECTIONS_VEHICLES_INSPECTORS_ID,
  INSPECTIONS_VEHICLE_CONDITIONS,
  INSPECTIONS_LOGS,
  INSPECTIONS_CHECKLIST_DYNAMIC_OPERATIONS,
  INSPECTIONS_CHECKLIST_ELECTRIC_HYBRID,
  INSPECTIONS_CHECKLIST_ENGINE_RUNNING,
  INSPECTIONS_CHECKLIST_INTERIOR,
  INSPECTION_DAMAGES_IMAGES,
  INSPECTION_DAMAGES_IMAGES_ID,
  INSPECTIONS_VEHICLES_SUMMARIES,
  INSPECTIONS_VEHICLES_MECHANICALS_NOTES,
  INSPECTIONS_MECHANICALS_WHEELS,
  INSPECTIONS_REPORTS_INITIAL,
  INSPECTIONS_VEHICLE_DAMAGES_CODES_SITE_PART,
} from '../Core';

type GetInspectionsReportInitial = (
  inspectionId: number,
) => AxiosPromise<ReportInitialData>;
type GetInspectionsVehicles = () => AxiosPromise<InspectionsData>;
type PostInspectionsVehicles = (
  body: GetInspectionsData,
) => AxiosPromise<InspectionsData>;
type UpdateInspectionsVehicles = (
  data: UpdateInspectionStatus,
) => AxiosPromise<UpdateInspectionStatusResponse>;
type GetInspectionsVehicle = (id: number) => AxiosPromise<InspectionsData>;
type GetInspectionsVehicleConditions = () => AxiosPromise<ConditionsData>;
type GetInspectionsVehicleDamages = (id: number) => AxiosPromise<DamagesData>;
type GetInspectionsVehicleDamageCodes = (
  siteId: number | string,
  partCode: number | string,
) => AxiosPromise<DamageCodesData>;
type UpdateInspectionsVehicleDamage = (
  data: UpdateDamage,
) => AxiosPromise<boolean>;
type CreateInspectionsVehiclesDamagesLines = (
  data: CreateDamageLineBody,
) => AxiosPromise<number>;
type UpdateInspectionsVehiclesDamagesLines = (
  data: UpdateDamageLineBody,
) => AxiosPromise<number>;
type GetInspectionsVehicleDamageParts =
  () => AxiosPromise<RegularDamagePartData>;
type UpdateInspectionsVehicleDamageParts = (
  data: AddDamagePartBody,
) => AxiosPromise<boolean>;
type UpdateInspectionsVehicleDamageActions = (
  data: AddDamageActionBody,
) => AxiosPromise<boolean>;
type GetInspectionsVehicleDamageActions = (
  id: number,
) => AxiosPromise<DamageActionData>;
type GetInspectionsVehicleDamagePartsById = (
  id: number,
) => AxiosPromise<DamagePartData>;
type GetInspectionsVehiclesParts = (
  partNumber: string,
) => AxiosPromise<PartData>;
type GetInspectionsVehiclesDamageActionsByFlatRateCode = (
  frCode: string,
) => AxiosPromise<FrCodeData>;
type DeleteInspectionsVehiclesDamagePart = (
  id: string,
) => AxiosPromise<boolean>;
type DeleteInspectionsVehiclesDamageActions = (
  id: string,
) => AxiosPromise<boolean>;
type CreateInspectionsVehiclesFlatRateCode = (
  data: FlatRate,
) => AxiosPromise<number>;
type GetInspectionsVehiclesInspectors = () => AxiosPromise<InspectorData>;
type GetInspectionsVehiclesInspector = (
  id: number,
) => AxiosPromise<InspectorData>;
type GetInspectionsVehiclesNotes = (id: number) => AxiosPromise<NotesData>;
type UpdateInspectionsVehiclesNotes = (
  data: UpdateNote,
) => AxiosPromise<number>;
type GetInspectionsLogs = () => AxiosPromise<LogsData>;
type GetInspectionsChecklistDynamicOperations = (
  id: number,
) => AxiosPromise<ChecklistDynamicOperationsData>;
type GetInspectionsChecklistElectricHybrid = (
  id: number,
) => AxiosPromise<ChecklistElectricHybridData>;
type GetInspectionsChecklistEngineRunning = (
  id: number,
) => AxiosPromise<ChecklistEngineRunningData>;
type GetInspectionsChecklistInterior = (
  id: number,
) => AxiosPromise<ChecklistInteriorData>;
type GetDamagesImages = (id: number) => AxiosPromise<DamagesImagesData>;
type GetInspectionsVehiclesSummaries = (
  id: number,
) => AxiosPromise<SummariesData>;
type GetInspectionsVehiclesMechanicalsNotes = (
  id: number,
) => AxiosPromise<MechanicalsNotesData>;
type GetInspectionsMechanicalssWheels = (
  id: number,
) => AxiosPromise<MechanicalsWeelsData>;

type UploadDamageImageData = {
  data: {
    vehicleDamageId: number;
    imageFileName: string;
    imageFileBase64: ArrayBuffer | string | null;
  };
};
type UploadDamageImage = (data: UploadDamageImageData) => AxiosPromise<boolean>;
type DeleteDamagesImages = (id: number) => AxiosPromise<boolean>;

class Inspections extends Core {
  getInspectionsReportsInitial: GetInspectionsReportInitial = (
    inspectionId: number,
  ) => this.get(INSPECTIONS_REPORTS_INITIAL(inspectionId));

  getInspectionsVehicles: GetInspectionsVehicles = async () =>
    this.get(INSPECTIONS_VEHICLES());

  postInspectionsVehicles: PostInspectionsVehicles = async (body) =>
    this.post<GetInspectionsData, AxiosPromise<InspectionsData>>(
      INSPECTIONS_VEHICLES(),
      body,
    );

  updateInspectionsVehicle: UpdateInspectionsVehicles = async (data) =>
    this.patch<
      UpdateInspectionStatus,
      AxiosPromise<UpdateInspectionStatusResponse>
    >(INSPECTIONS_VEHICLES(), data);

  getInspectionsVehicle: GetInspectionsVehicle = async (id) =>
    this.get(INSPECTIONS_VEHICLE(id));

  getInspectionsVehicleConditions: GetInspectionsVehicleConditions = async () =>
    this.get(INSPECTIONS_VEHICLE_CONDITIONS());

  getInspectionsVehicleDamages: GetInspectionsVehicleDamages = async (id) =>
    this.get(INSPECTIONS_VEHICLE_DAMAGES(id));

  getInspectionsVehicleDamageCodes: GetInspectionsVehicleDamageCodes = async (
    siteId,
    partCode,
  ) => this.get(INSPECTIONS_VEHICLE_DAMAGES_CODES_SITE_PART(siteId, partCode));

  updateInspectionsVehicleDamage: UpdateInspectionsVehicleDamage = async (
    data,
  ) => this.patch(INSPECTIONS_VEHICLE_DAMAGE(), data);

  getInspectionsVehicleDamageParts: GetInspectionsVehicleDamageParts =
    async () => this.get(INSPECTIONS_VEHICLE_DAMAGE_PARTS());

  updateInspectionsVehicleDamageParts: UpdateInspectionsVehicleDamageParts =
    async (data) => this.post(INSPECTIONS_VEHICLE_DAMAGE_PARTS(), data);

  updateInspectionsVehicleDamageActions: UpdateInspectionsVehicleDamageActions =
    async (data) => this.put(INSPECTIONS_VEHICLE_DAMAGE_ACTIONS(), data);

  getInspectionsVehicleDamageActions: GetInspectionsVehicleDamageActions =
    async (id) => this.get(INSPECTIONS_VEHICLE_DAMAGE_ACTIONS_BY_ID(id));

  getInspectionsVehicleDamagePartsById: GetInspectionsVehicleDamagePartsById =
    async (id) => this.get(INSPECTIONS_VEHICLE_DAMAGE_PARTS_BY_ID(id));

  getInspectionsVehiclesParts: GetInspectionsVehiclesParts = async (
    partNumber: string,
  ) => this.get(INSPECTIONS_VEHICLES_PARTS(partNumber));

  getInspectionsVehiclesDamageActionsByFlatRateCode: GetInspectionsVehiclesDamageActionsByFlatRateCode =
    async (frCode: string) =>
      this.get(INSPECTIONS_VEHICLES_DAMAGE_ACTIONS_BY_FLAT_RATE_CODE(frCode));

  deleteInspectionsVehiclesDamagePart: DeleteInspectionsVehiclesDamagePart =
    async (id: string) =>
      this.delete<undefined, AxiosPromise<boolean>>(
        INSPECTIONS_VEHICLES_DAMAGE_PARTS_ID(id),
      );

  deleteInspectionsVehiclesDamageAction: DeleteInspectionsVehiclesDamageActions =
    async (id: string) =>
      this.delete<undefined, AxiosPromise<boolean>>(
        DELETE_INSPECTIONS_VEHICLES_DAMAGE_ACTIONS_BY_ID(id),
      );

  createInspectionsVehiclesFlatRateCode: CreateInspectionsVehiclesFlatRateCode =
    async (data) => this.put(INSPECTIONS_CREATE_FLAT_RATE_CODE(), data);

  createInspectionsVehiclesDamagesLines: CreateInspectionsVehiclesDamagesLines =
    async (data) => this.post(INSPECTIONS_VEHICLES_DAMAGES_LINES(), data);

  updateInspectionsVehiclesDamagesLines: UpdateInspectionsVehiclesDamagesLines =
    async (data) => this.put(INSPECTIONS_VEHICLES_DAMAGES_LINES_BY_ID(data.damageLineId), data);

  getInspectionsVehiclesInspectors: GetInspectionsVehiclesInspectors =
    async () => this.get(INSPECTIONS_VEHICLES_INSPECTORS());

  getInspectionsVehiclesInspector: GetInspectionsVehiclesInspector = async (
    id: number,
  ) => this.get(INSPECTIONS_VEHICLES_INSPECTORS_ID(id));

  getInspectionsVehiclesNotes: GetInspectionsVehiclesNotes = async (
    id: number,
  ) => this.get(INSPECTIONS_VEHICLES_ID_NOTES(id));

  updateInspectionsVehiclesNotes: UpdateInspectionsVehiclesNotes = async (
    data,
  ) =>
    this.patch<UpdateNote, AxiosPromise<number>>(
      INSPECTIONS_VEHICLES_NOTES(),
      data,
    );

  getInspectionsLogs: GetInspectionsLogs = async () =>
    this.get(INSPECTIONS_LOGS());

  getInspectionsChecklistDynamicOperations: GetInspectionsChecklistDynamicOperations =
    async (id: number) =>
      this.get(INSPECTIONS_CHECKLIST_DYNAMIC_OPERATIONS(id));

  getInspectionsChecklistElectricHybrid: GetInspectionsChecklistElectricHybrid =
    async (id: number) => this.get(INSPECTIONS_CHECKLIST_ELECTRIC_HYBRID(id));

  getInspectionsChecklistEngineRunning: GetInspectionsChecklistEngineRunning =
    async (id: number) => this.get(INSPECTIONS_CHECKLIST_ENGINE_RUNNING(id));

  getInspectionsChecklistInterior: GetInspectionsChecklistInterior = async (
    id: number,
  ) => this.get(INSPECTIONS_CHECKLIST_INTERIOR(id));

  getInspectionsDamagesImages: GetDamagesImages = async (id: number) =>
    this.get(INSPECTION_DAMAGES_IMAGES(id));

  uploadInspectionsDamagesImages: UploadDamageImage = async ({
    data,
  }: UploadDamageImageData) =>
    this.post<typeof data, AxiosPromise<boolean>>(
      INSPECTION_DAMAGES_IMAGES(),
      data,
    );

  deleteInspectionsDamagesImage: DeleteDamagesImages = async (id: number) =>
    this.delete<undefined, AxiosPromise<boolean>>(
      INSPECTION_DAMAGES_IMAGES_ID(id),
    );

  getInspectionsVehiclesSummaries: GetInspectionsVehiclesSummaries = async (
    id: number,
  ) => this.get(INSPECTIONS_VEHICLES_SUMMARIES(id));

  getInspectionsVehiclesMechanicalsNotes: GetInspectionsVehiclesMechanicalsNotes =
    async (id: number) => this.get(INSPECTIONS_VEHICLES_MECHANICALS_NOTES(id));

  getInspectionsMechanicalsWheels: GetInspectionsMechanicalssWheels = async (
    id: number,
  ) => this.get(INSPECTIONS_MECHANICALS_WHEELS(id));
}

export const inspections = new Inspections();
