import React from 'react';
import { Col, Row } from '@/ui';
import { Title } from '@/ui/components/Title';
import { Icons } from '@/ui/components/Icon';
import { useTranslate } from '@/translations';
import { useStorage, useStorageGraph } from '@/api/Storage';
import { Loader } from '@/ui/components/Loader';
import { StorageChart } from './components/StorageChart';
import { StorageDataGrid } from './components/StorageDataGrid';
import { StorageContainer } from './Storage.styled';

export const Storage = () => {
  const { t } = useTranslate();
  const { data: storageData, isLoading: isStorageLoading } = useStorage();
  const { data: graphData, isLoading: isGraphLoading } = useStorageGraph();

  if (isStorageLoading || isGraphLoading) return <Loader />;

  return (
    <StorageContainer fluid>
      <Title title={t('compoundStorage')} icon={Icons.compound} />
      <Row>
        <Col lg={6} md={12} sm={12}>
          <StorageDataGrid storageData={storageData?.entities} />
        </Col>
        <Col lg={6} md={12} sm={12} className="mt-5 mt-lg-0">
          <StorageChart graphData={graphData?.entities} />
        </Col>
      </Row>
    </StorageContainer>
  );
};
