import React, { FC } from 'react';
import { FieldProps } from './types';

export const Field: FC<FieldProps> = ({ label, value, className }) => {
  const labelClassName = className?.labelClassName || 'w-50';
  const valueClassName = className?.valueClassName || 'w-50';

  return (
    <div className="dx-field m-0 d-flex align-items-center text-muted">
      {label && (
        <div className={`dx-field-label fw-bold ${labelClassName}`}>
          {label}:
        </div>
      )}
      <div
        className={`${label ? '' : 'fw-bold'} dx-field-value ${valueClassName}`}
      >
        {value}
      </div>
    </div>
  );
};
